<template>
    <div>
        <NewCreate v-if="is_trucking()" />
        <Create v-else />
    </div>
</template>
<script>
import Create from './create';
import NewCreate from './create_trucking_form'
export default {
    components : {Create, NewCreate}
}
</script>