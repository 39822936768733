var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('CRow',{staticClass:"mb-0"},[_c('CCol',{attrs:{"lg":"3"}},[_c('CInput',{attrs:{"placeholder":"WO No."},model:{value:(_vm.wo_no),callback:function ($$v) {_vm.wo_no=$$v},expression:"wo_no"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Date Needed Date from"},model:{value:(_vm.date_needed_from),callback:function ($$v) {_vm.date_needed_from=$$v},expression:"date_needed_from"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Date Needed Date to"},model:{value:(_vm.date_needed_to),callback:function ($$v) {_vm.date_needed_to=$$v},expression:"date_needed_to"}})],1),_c('CCol',{attrs:{"lg":"1"}}),_c('CCol',{staticClass:"float-right",attrs:{"lg":"2"}},[_c('CButton',{attrs:{"size":"sm","block":"","color":"info"},on:{"click":_vm.search}},[_c('i',{staticClass:"icon-magnifier"}),_vm._v(" Search")])],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"setting_name","options":_vm.depotList.data,"reduce":function (item) { return item.id; },"placeholder":"Depot"},model:{value:(_vm.depot_id),callback:function ($$v) {_vm.depot_id=$$v},expression:"depot_id"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"setting_name","options":[
              {
                  setting_name: 'Vehicle',
                  id: 'vehicle'
              },
              {
                  setting_name: 'Equipment',
                  id: 'equipment'
              },
              {
                  setting_name: 'Furnishing',
                  id: 'furnishing'
              }
          ],"reduce":function (item) { return item.id; },"placeholder":"Asset Type"},model:{value:(_vm.asset_type),callback:function ($$v) {_vm.asset_type=$$v},expression:"asset_type"}})],1),_c('CCol',{attrs:{"lg":"3"}},[(_vm.asset_type == 'equipment')?_c('v-select',{attrs:{"label":"asset_no","options":_vm.equipmentList.data,"reduce":function (item) { return item.id; },"placeholder":"Asset No."},model:{value:(_vm.asset_no),callback:function ($$v) {_vm.asset_no=$$v},expression:"asset_no"}}):_vm._e(),(_vm.asset_type == 'furnishing')?_c('v-select',{attrs:{"label":"asset_no","options":_vm.furnishingList.data,"reduce":function (item) { return item.id; },"placeholder":"Asset No."},model:{value:(_vm.asset_no),callback:function ($$v) {_vm.asset_no=$$v},expression:"asset_no"}}):_vm._e(),(_vm.asset_type == 'vehicle')?_c('v-select',{attrs:{"label":"plate_no","options":_vm.vehicleList.data,"reduce":function (item) { return item.id; },"placeholder":"Plate No."},model:{value:(_vm.asset_no),callback:function ($$v) {_vm.asset_no=$$v},expression:"asset_no"}}):_vm._e()],1),_c('CCol',{attrs:{"lg":"1"}}),_c('CCol',{staticClass:"float-right",attrs:{"lg":"2"}},[_c('CButton',{attrs:{"size":"sm","block":"","color":"info"},on:{"click":_vm.clearSearch}},[_vm._v("Clear Search")])],1)],1),_c('CRow',{staticClass:"mt-3"},[(!_vm.is_trucking())?_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"customer_name","options":_vm.customerList.data,"reduce":function (item) { return item.id; },"placeholder":"Select Dept / Proj"},model:{value:(_vm.dept_id),callback:function ($$v) {_vm.dept_id=$$v},expression:"dept_id"}})],1):_vm._e(),(!_vm.is_trucking())?_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"setting_name","options":[
              {
                  setting_name: 'Preventive Maintenance',
                  id: 'preventive maintenance'
              },
              {
                  setting_name: 'Repair',
                  id: 'repair'
              },
              {
                  setting_name: 'Change',
                  id: 'change'
              },
            {
              setting_name: 'Dismantling',
              id: 'dismantling'
            }, 
            {
              setting_name: 'Installation',
              id: 'installation'
            },
            {
              setting_name: 'Checking & Diagnose',
              id: 'checking & diagnose'
            },
            {
              setting_name: 'Rehabilitation',
              id: 'rehabilitation'
            }, 
            {
              setting_name: 'Recondition',
              id: 'recondition'
            }, 
              {
                  setting_name: 'Others',
                  id: 'others'
              } ],"reduce":function (item) { return item.id; },"placeholder":"WO Type"},model:{value:(_vm.wo_type),callback:function ($$v) {_vm.wo_type=$$v},expression:"wo_type"}})],1):_vm._e(),_c('CCol',{attrs:{"lg":"1"}}),_c('CCol',{staticClass:"float-right",attrs:{"lg":"2"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }